import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import {
  Button,
  Divider,
  Drawer,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Logo from "@image/Fliption_logo_1.png";
import awsmobile from "@assets/aws-exports";
import { colors } from "@assets/styles/colors";
import { HeaderBt } from "src/feature/ui/Common";
import { MobileIcon } from "src/styles/Header";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const key = `CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.LastAuthUser`;
  const userId = localStorage.getItem(key);

  // console.log(location);
  // 메뉴
  const menuList = [
    { path: "swap", label: "Swap" },
    { path: "contents", label: "V Content" },
    // { path: "request", label: "Request V Face" },
  ];

  // 반응형
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [mOpen, setMOpen] = useState(false);

  // 로그아웃
  const logout = async () => {
    try {
      await Auth.signOut();
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        mx: {
          xs: "40px",
          lg: "80px",
        },
        height: {
          xs: "60px",
          md: "80px",
        },
      }}
    >
      {isTablet ? (
        <>
          <img
            src={Logo}
            width="100px"
            alt="Fliption"
            style={{
              objectFit: "contain",
              cursor: "pointer",
              zIndex: 1300,
            }}
            onClick={() => {
              navigate("/");
            }}
          />
          <MobileIcon
            open={mOpen}
            onClick={() => {
              setMOpen(!mOpen);
            }}
          >
            <i>
              <span />
            </i>
          </MobileIcon>
          <Drawer
            anchor="top"
            open={mOpen}
            onClose={() => {
              setMOpen(false);
            }}
            PaperProps={{
              sx: {
                pt: "40px",
                pb: "20px",
                backgroundColor: colors.variant.grey100,
              },
            }}
          >
            <Stack alignItems="center" gap="15px" mx="40px">
              {menuList.map((item) => (
                <HeaderBt
                  key={item.path}
                  selected={
                    item.path === "swap"
                      ? location.pathname === "/" ||
                        location.pathname.includes(item.path)
                      : location.pathname.includes(item.path)
                  }
                  onClick={() => {
                    setMOpen(false);
                    navigate(`/${item.path}`);
                  }}
                  sx={{
                    fontSize: "18px",
                  }}
                >
                  {item.label}
                </HeaderBt>
              ))}
              <Stack direction="row" justifyContent="flex-end" width="100%">
                <Button sx={{ fontSize: 16 }} onClick={logout}>
                  로그아웃
                </Button>
              </Stack>
            </Stack>
          </Drawer>
        </>
      ) : (
        <>
          <Stack direction="row" gap="40px">
            <img
              src={Logo}
              width="130px"
              alt="Fliption"
              style={{
                objectFit: "contain",
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
            <Stack direction="row" alignItems="center" gap="40px" ml="10px">
              {menuList.map((item) => (
                <HeaderBt
                  key={item.path}
                  selected={
                    item.path === "swap"
                      ? location.pathname === "/" ||
                        location.pathname.includes(item.path)
                      : location.pathname.includes(item.path)
                  }
                  onClick={() => {
                    navigate(`/${item.path}`);
                  }}
                  sx={{
                    fontSize: {
                      xs: "16px !important",
                      lg: "18px !important",
                    },
                  }}
                >
                  {item.label}
                </HeaderBt>
              ))}
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" gap="10px">
            <Stack direction="row" alignItems="center" gap="5px">
              <Typography color={colors.variant.grey150}>
                안녕하세요,
              </Typography>
              <Typography fontSize={18} fontWeight={500} color="#fff">
                {userId}
              </Typography>
              <Typography color={colors.variant.grey150}>님</Typography>
            </Stack>
            <Button onClick={logout}>로그아웃</Button>
          </Stack>
        </>
      )}
    </Stack>
  );
};

export default Header;
