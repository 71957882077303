import React, { useEffect, useState } from "react";
import { ContentsContainer, GreyContainer, StyledButton } from "../ui/Common";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { colors } from "@assets/styles/colors";
import {
  ColorlibConnector,
  ColorlibStepIcon,
  ImageSelectBox,
} from "./VirtualFaceStyle";
import VirtualFaceUpload from "./VirtualFaceUpload";
import VirtualFaceSource from "./VirtualFaceSource";
import VirtualFaceSetting from "./VirtualFaceSetting";
import VirtualFaceComplete from "./VirtualFaceComplete";
import { useNavigate, useParams } from "react-router-dom";
import { swapApi } from "@assets/api";
import axios from "axios";
import { useCreateVContentMutation } from "src/lib/api";
import UploadLoading from "../ui/UploadLoading";
import awsmobile from "@assets/aws-exports";
import { postTimeStamp } from "../assets/timeStamp";
import moment from "moment";

export type resultType = {
  status: string;
  temporary_directory: string;
  "the number of frame"?: number;
  url: string;
};

const VirtualFaceStep = () => {
  const { type } = useParams();

  const navigate = useNavigate();

  const key = `CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.LastAuthUser`;
  const userId = localStorage.getItem(key);

  const [activeStep, setActiveStep] = useState(0);

  const steps = ["파일 업로드", "변환얼굴 선택", "변환관련 설정", "변환 완료"];

  // 타겟 파일
  const [file, setFile] = useState<File | null>(null);

  // 소스 파일
  const [sourceFile, setSourceFile] = useState<File | null>(null);

  // 특정 얼굴
  const [objectFile, setObjectFile] = useState<File | null>(null);

  // 결과 정보
  const [resultState, setResultState] = useState<resultType | null>(null);

  // 소스 설정
  useEffect(() => {
    if (type !== "image" && type !== "video") {
      navigate("/swap");
    }
  }, [type]);

  // 변환 설정
  const [threshold, setThreshold] = useState(0.46);
  const [swapValue, setSwapValue] = useState(1);

  const getThreshold = async () => {
    await swapApi
      .get("/set_threshold")
      .then((res) => {
        if (res.data.result) {
          setThreshold(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSwapValue = async () => {
    await swapApi
      .get("/set_swapmode")
      .then((res) => {
        if (res.data.result) {
          setSwapValue(res.data.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getThreshold();
    getSwapValue();
  }, []);

  // 변환 이미지 등록
  const { mutate } = useCreateVContentMutation();

  // 로딩
  const [loading, setLoading] = useState(false);
  // const [loadingProgress, setLoadingProgress] = useState(0);

  // 이미지 변환
  const handleSubmit = async () => {
    if (!file || !sourceFile) {
      alert("파일을 업로드 해주세요.");
      return;
    }
    // console.log(file, sourceFile);
    // setLoadingProgress(0);
    try {
      setLoading(true);
      // console.log(threshold, swapValue);

      const postThreshold = await swapApi.post("/set_threshold", {
        detect_threshold: threshold,
      });
      // setLoadingProgress(20);

      const postSwapValue = await swapApi.post("/set_swapmode", {
        swap_mode: swapValue,
      });
      // setLoadingProgress(40);

      if (postThreshold.data && postSwapValue.data) {
        setThreshold(parseFloat(postThreshold.data.split(":")[1]) ?? 0);
        setSwapValue(parseFloat(postSwapValue.data.split(":")[1]) ?? 0);
      }

      const timeStamp = await postTimeStamp();

      // setLoadingProgress(70);

      // console.log(threshold, swapValue);
      // console.log("설정 완료");

      let formData = new FormData();

      if (type === "video") {
        formData.append("target_video", file);
      } else {
        formData.append("target_image", file);
      }

      formData.append("source_image", sourceFile);

      if (objectFile) {
        formData.append("object_image", objectFile);
      }

      const fileExtension = file.name.split(".").pop();

      if (type === "video" && timeStamp) {
        formData.append("time_stamp", timeStamp.time_stamp);
      }

      try {
        const swapRes =
          type === "video"
            ? await swapApi.post("/synthesize_video", formData)
            : await swapApi.post("/synthesize_image", formData);

        // console.log("전송 완료");

        const result = swapRes.data.result;
        console.log(result);
        // setLoadingProgress(80);

        if (result && result.length > 0 && result[0].url) {
          setResultState(result[0]);
          const filename = result[0].url.split("/").pop();
          await mutate({
            input: {
              userID: userId ? userId : "test@test.com",
              VFaceID: "test",
              fileName: filename
                ? filename
                : `result-${timeStamp.time_stamp}.${fileExtension}`,
              storage: result[0].url,
              isBookmarked: false,
              type: type === "video" ? "video" : "image",
            },
          });
          setActiveStep(3);
        }
      } catch (error: any) {
        console.log(error);
        if (error.code === "ERR_NETWORK") {
          alert(
            "컨텐츠 변환에 다소 시간이 지연되고 있습니다. 잠시 후 V content 페이지에서 변환 결과를 확인해주세요!"
          );
          await mutate({
            input: {
              userID: userId ? userId : "test@test.com",
              VFaceID: "test",
              fileName: `result-${timeStamp.time_stamp}.${fileExtension}`,
              storage: `https://fliption-web-bucket-dev.s3.ap-northeast-2.amazonaws.com/cj-test/result-video/${moment().format(
                "YYYY/M/D"
              )}/result-${timeStamp.time_stamp}.${fileExtension}`,
              isBookmarked: false,
              type: "video",
            },
          });
          setActiveStep(0);
          setFile(null);
          setSourceFile(null);
          setObjectFile(null);
        } else {
          throw error;
        }
      }

      // const swapRes =
      //   type === "video"
      //     ? await swapApi.post("/synthesize_video", formData)
      //     : await swapApi.post("/synthesize_image", formData);

      // // console.log("전송 완료");

      // const result = swapRes.data.result;
      // console.log(result);
      // // setLoadingProgress(80);

      // if (result && result.length > 0 && result[0].url) {
      //   setResultState(result[0]);
      //   const filename = result[0].url.split("/").pop();
      //   if (type === "image") {
      //     await mutate({
      //       input: {
      //         userID: userId ? userId : "test@test.com",
      //         VFaceID: "test",
      //         fileName: filename ? filename : file.name,
      //         storage: result[0].url,
      //         isBookmarked: false,
      //         type: "image",
      //       },
      //     });
      //   }
      //   setActiveStep(3);
      // }
    } catch (err) {
      console.error("Error:", err);
      alert(
        `변환 과정에서 오류가 발생했습니다. ${
          type === "video"
            ? "영상의 화질이 높거나 길이가 길고, 변환할 인물이 많을 경우 변환이 실패할 수 있습니다."
            : "이미지의 해상도가 높거나 변환할 인물이 많을 경우 변환이 실패할 수 있습니다."
        } 다시 시도해 주세요.`
      );
      setActiveStep(0);
      setFile(null);
      setSourceFile(null);
      setObjectFile(null);
    } finally {
      setLoading(false);
      // setLoadingProgress(0);
    }
  };

  // blob 객체 생성
  const urlToBlob = async (url: string) => {
    const response = await fetch(url, { cache: "no-cache" });
    const blob = await response.blob();

    const urlObject = new URL(url);
    const extension = urlObject.pathname.split("/").pop()?.split(".").pop();

    // Blob을 File로 변환
    const file = new File([blob], `frame.${extension}`, {
      type: `image/${extension}`,
    });

    return file;
  };

  // 수정 기능
  const [fixImgList, setFixImgList] = useState<string[]>([]);
  const [selectedImg, setSelectedImg] = useState("");
  const [selectFrame, setSelectFrame] = useState(""); // 프레임 선택
  const [findFrame, setFindFrame] = useState<string[]>([]); // 프레임 특정 얼굴 리스트

  // console.log(resultState);

  // 오류 시 프레임 url 생성
  const generateFixList = (baseUrl: string, count: number) => {
    const list = [];
    for (let i = 0; i < count; i++) {
      const frameNum = String(i).padStart(7, "0");
      const url = `${baseUrl}/frame_${frameNum}.jpg`;
      list.push(url);
    }
    return list;
  };

  // 수정 파일 업로드
  const handleEditUpload = async (type: "image" | "video") => {
    try {
      if (!resultState) {
        alert("오류가 발생했습니다. 다시 시도해 주세요.");
        return;
      }
      setLoading(true);

      // const timeStamp = await postTimeStamp();

      // setLoadingProgress(0);
      const url = new URL(resultState.url);
      const filename = url.pathname.split("/").pop();
      const numbers = resultState.temporary_directory?.match(/\d+/g)?.join("");
      const extension = filename?.split(".").pop();
      const formData = new FormData();
      if (type === "image") {
        formData.append("target_image", "target-" + numbers + "." + extension);
      } else {
        // formData.append("target_video", resultState.url);
        formData.append("target_video", "target-" + numbers + "." + extension);
        if (numbers) {
          formData.append("time_stamp", numbers);
        }
      }
      formData.append("temporary_directory", resultState.temporary_directory);
      // setLoadingProgress(30);

      try {
        const res =
          type === "image"
            ? await swapApi.post("/find_face", formData)
            : await swapApi.post("/video_decoding", formData);

        // setLoadingProgress(60);
        console.log(res);
        if (res.data.url && res.data.url.length > 0) {
          // setLoadingProgress(100);
          setFixImgList(res.data.url);
          return true;
        }
      } catch (err: any) {
        console.log(err);
        if (
          err.code === "ERR_NETWORK" &&
          type === "video" &&
          resultState["the number of frame"]
        ) {
          alert(
            "프레임 변환에 다소 시간이 지연되고 있습니다. 잠시만 기다려주세요."
          );
          const baseUrl = `https://fliption-web-bucket-dev.s3.ap-northeast-2.amazonaws.com/cj-test/frame-image/${moment().format(
            "YYYY/M/D"
          )}/frame-${numbers}`;
          const frameList = generateFixList(
            baseUrl,
            resultState["the number of frame"]
          );
          setFixImgList(frameList);
          return true;
        } else {
          throw err;
        }
      }
    } catch (error) {
      console.error(error);
      alert("오류가 발생했습니다. 다시 시도해 주세요.");
      return false;
    } finally {
      setLoading(false);
      // setLoadingProgress(0);
    }
  };

  // 수정 변환 - 이미지
  const handleEdit = async () => {
    if (!file || !sourceFile || !selectedImg) {
      alert("오류가 발생했습니다. 다시 한번 확인해 주세요.");
      return false;
    }
    setLoading(true);
    // setLoadingProgress(0);
    try {
      let formData = new FormData();

      formData.append("target_image", file);
      formData.append("source_image", sourceFile);

      const objectImg = await urlToBlob(selectedImg);
      formData.append("object_image", objectImg);

      // setLoadingProgress(30);

      const swapRes = await swapApi.post("/synthesize_image", formData);

      // setLoadingProgress(60);

      const result = swapRes.data.result;
      console.log(result);

      if (result && result.length > 0 && result[0].url) {
        const url = new URL(result[0].url);
        const urlWithoutQuery = url.origin + url.pathname;
        const filename = url.pathname.split("/").pop();

        await handleDeleteTemp(resultState?.temporary_directory ?? "");

        // setLoadingProgress(100);
        setResultState({ ...result[0], url: urlWithoutQuery });
        await mutate({
          input: {
            userID: userId ? userId : "test@test.com",
            VFaceID: "test",
            fileName: filename ? filename : url.pathname,
            storage: urlWithoutQuery,
            isBookmarked: false,
            type: "image",
          },
        });
        setActiveStep(3);
        return true;
      }
    } catch (err) {
      console.error("Error:", err);
      alert("변환 과정에서 오류가 발생했습니다. 다시 시도해 주세요.");
      return false;
    } finally {
      setLoading(false);
      // setLoadingProgress(0);
    }
  };

  // 프레임 특정 얼굴 찾기
  const handleFrameFace = async (url: string) => {
    try {
      if (!resultState) {
        alert("오류가 발생했습니다. 다시 시도해 주세요.");
        return;
      }
      setLoading(true);

      const selectUrl = new URL(url);
      const filename = selectUrl.pathname.split("/").pop();
      const numbers = filename?.match(/\d+/g)?.join("");

      const formData = new FormData();

      formData.append("target_image", filename ?? "");
      if (filename?.includes("result")) {
        formData.append("temporary_directory", "static/" + numbers);
      } else {
        formData.append(
          "temporary_directory",
          resultState?.temporary_directory + "/frame"
        );
      }

      const res = await swapApi.post("/find_face", formData);

      console.log(res);

      if (res.data.url && res.data.url.length > 0) {
        setSelectFrame(url);
        setFindFrame(res.data.url);
        return true;
      }
    } catch (error) {
      console.error(error);
      alert("오류가 발생했습니다. 다시 시도해 주세요.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  // 프레임 파일명 변환
  const formatFrameNumber = (idx: number) => {
    const paddedNum = String(idx).padStart(7, "0");
    return `frame_${paddedNum}.jpg`;
  };

  //  수정 변환 - 영상
  const handleEditFrame = async (
    url: string,
    idx: number,
    objectImg?: string
  ) => {
    if (!url || !sourceFile) {
      alert("오류가 발생했습니다. 다시 시도해 주세요.");
      return;
    }
    try {
      setLoading(true);

      const selectFile = await urlToBlob(url);

      let formData = new FormData();

      formData.append("target_image", selectFile);
      formData.append("source_image", sourceFile);
      if (objectImg) {
        const objectBlob = await urlToBlob(objectImg);
        formData.append("object_image", objectBlob);
      }

      const swapRes = await swapApi.post("/synthesize_image", formData);

      const result = swapRes.data.result;
      console.log(result);

      if (result && result.length > 0 && result[0].url) {
        let saveData = new FormData();

        const savePath =
          resultState?.temporary_directory +
          "/result_frame/" +
          formatFrameNumber(idx);

        saveData.append("save_path", savePath);
        saveData.append("image_url", result[0].url);
        const saveRes = await swapApi.post("/save_image", saveData);

        console.log(saveRes);

        // await handleDeleteTemp(result[0].temporary_directory ?? "");

        const newFixImgList = fixImgList.map((item) => {
          const itemUrl = new URL(item);
          const itemUrlWithoutQuery = itemUrl.origin + itemUrl.pathname;
          return itemUrlWithoutQuery === url ? result[0].url : item;
        });
        setFixImgList(newFixImgList);

        return true;
      }
    } catch (err) {
      console.error("Error:", err);
      alert(
        "변환 과정에서 오류가 발생했습니다. 흐릿하거나 낮은 화질의 프레임의 경우, 특정 얼굴 변환이 불가능합니다. 다시 시도해 주세요."
      );
      return false;
    } finally {
      setLoading(false);
      // setLoadingProgress(0);
    }
  };

  const handleEncodingVideo = async () => {
    try {
      if (!resultState) {
        alert("오류가 발생했습니다. 다시 시도해 주세요.");
        return;
      }
      setLoading(true);

      const timeStamp = await postTimeStamp();

      // const url = new URL(resultState.url);
      // const urlWithoutQuery = url.origin + url.pathname;

      const formData = new FormData();
      formData.append("target_video", resultState.url);
      formData.append("temporary_directory", resultState.temporary_directory);

      if (timeStamp) {
        formData.append("time_stamp", timeStamp.time_stamp);
      }

      try {
        const encodingRes = await swapApi.post("/video_encoding", formData);

        const encodingResult = encodingRes.data;

        console.log(encodingResult);

        if (encodingResult && encodingResult.url) {
          const resUrl = new URL(encodingResult.url);
          const resFilename = resUrl.pathname.split("/").pop();

          await handleDeleteTemp(resultState?.temporary_directory + "/frame");

          setResultState(encodingResult);
          await mutate({
            input: {
              userID: userId ? userId : "test@test.com",
              VFaceID: "test",
              fileName: resFilename ? resFilename : resUrl.pathname,
              storage: encodingResult.url,
              isBookmarked: false,
              type: "video",
            },
          });
          return true;
        }
      } catch (err: any) {
        console.log(err);
        if (err.code === "ERR_NETWORK") {
          alert(
            "컨텐츠 변환에 다소 시간이 지연되고 있습니다. 잠시 후 V content 페이지에서 변환 결과를 확인해주세요!"
          );
          const fileExtension = file?.name.split(".").pop();
          await mutate({
            input: {
              userID: userId ? userId : "test@test.com",
              VFaceID: "test",
              fileName: `result-${timeStamp.time_stamp}.${fileExtension}`,
              storage: `https://fliption-web-bucket-dev.s3.ap-northeast-2.amazonaws.com/cj-test/result-video/${moment().format(
                "YYYY/M/D"
              )}/result-${timeStamp.time_stamp}.${fileExtension}`,
              isBookmarked: false,
              type: "video",
            },
          });
          return true;
        } else {
          throw err;
        }
      }
    } catch (error) {
      console.error(error);
      alert("오류가 발생했습니다. 다시 시도해 주세요.");
      return false;
    } finally {
      setLoading(false);
    }
  };

  // 임시 폴더 삭제
  const handleDeleteTemp = async (url: string) => {
    const formData = new FormData();
    formData.append("temporary_directory", url);
    const res = await swapApi.delete("/delete_temporary", { data: formData });
    console.log(res);
  };

  const props = {
    type,
    file,
    setFile,
    setActiveStep,
  };
  const sourceProps = {
    type,
    file,
    setFile,
    sourceFile,
    objectFile,
    setObjectFile,
    setSourceFile,
    setActiveStep,
  };
  const settingProps = {
    type,
    file,
    setFile,
    setActiveStep,
    threshold,
    setThreshold,
    swapValue,
    setSwapValue,
    handleSubmit,
  };
  const resultProps = {
    type,
    file,
    setFile,
    resultState,
    setResultState,
    fixImgList,
    setFixImgList,
    selectedImg,
    setSelectedImg,
    handleEdit,
    handleEditUpload,
    handleEditFrame,
    handleEncodingVideo,
    selectFrame,
    setSelectFrame,
    findFrame,
    setFindFrame,
    handleFrameFace,
  };

  return (
    <ContentsContainer>
      <Stack
        // width="100%"
        justifyContent="center"
        alignItems="center"
        mb="40px"
      >
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<ColorlibConnector />}
          sx={{
            width: {
              xs: "100%",
              md: "50%",
            },
          }}
        >
          {steps.map((label, idx) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                <Typography
                  fontSize={16}
                  fontWeight={600}
                  color={
                    activeStep === idx ? colors.point : colors.variant.grey150
                  }
                  textTransform="uppercase"
                >
                  Step{idx + 1}
                </Typography>
                <Typography
                  lineHeight="16px"
                  fontWeight={500}
                  color={colors.variant.grey150}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
      <div style={{ flexGrow: 1 }}>
        {activeStep === 0 ? (
          <VirtualFaceUpload {...props} />
        ) : activeStep === 1 ? (
          <VirtualFaceSource {...sourceProps} />
        ) : activeStep === 2 ? (
          <VirtualFaceSetting {...settingProps} />
        ) : (
          <VirtualFaceComplete {...resultProps} />
        )}
      </div>
      {loading && <UploadLoading />}
    </ContentsContainer>
  );
};

export default VirtualFaceStep;
