import { LinearProgress, Stack, Typography } from "@mui/material";
import React from "react";
import Logo from "@image/Fliption_logo_1.png";

const UploadLoading = () => {
  return (
    <Stack
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
        gap: "40px",
        zIndex: 1400,
        backgroundColor: "#00000095",
      }}
    >
      <img
        src={Logo}
        width="150px"
        alt="Fliption"
        style={{
          objectFit: "contain",
          zIndex: 1300,
        }}
      />
      <Stack gap="20px" width="40%">
        <LinearProgress
          // value={progress}
          // variant="determinate"
          sx={{ height: "10px", borderRadius: "8px" }}
        />
        <Stack direction="row" justifyContent="center">
          <Typography fontSize={24} fontWeight={600} color="#ced4da">
            Please wait...
          </Typography>
          {/* <Typography fontSize={20} fontWeight={600}>
            {progress} %
          </Typography> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default UploadLoading;
