import { Dialog, DialogContent } from "@mui/material";
import React from "react";

type ImageDialogType = {
  enlargeImage: {
    open: boolean;
    url: string;
    large: boolean;
  };
  setEnlargeImage: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      url: string;
      large: boolean;
    }>
  >;
};

function ImageDialog(props: ImageDialogType) {
  const { enlargeImage, setEnlargeImage } = props;
  return (
    <Dialog
      open={enlargeImage.open}
      onClose={() => setEnlargeImage({ open: false, url: "", large: false })}
      PaperProps={{
        sx: {
          // width: "70%",
          background: "#2e2e2e",
        },
      }}
      maxWidth="lg"
    >
      {enlargeImage.open && (
        <DialogContent>
          <img
            src={enlargeImage.url}
            style={
              enlargeImage.large
                ? {
                    cursor: "zoom-out",
                  }
                : {
                    width: 800,
                    height: 800,
                    objectFit: "contain",
                    cursor: "zoom-in",
                  }
            }
            alt="enlargeImage"
            onClick={() =>
              setEnlargeImage((enlargeImage) => {
                return { ...enlargeImage, large: !enlargeImage.large };
              })
            }
          />
        </DialogContent>
      )}
    </Dialog>
  );
}

export default ImageDialog;
