import { colors } from "@assets/styles/colors";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import React from "react";

interface MStepBtProps {
  role: "prev" | "next";
  disabled?: boolean;
  handleClick: () => void;
}

const MStepBt = (props: MStepBtProps) => {
  const { role, disabled, handleClick } = props;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="5px"
      sx={{
        cursor: disabled ? "default" : "pointer",
      }}
      onClick={handleClick}
    >
      {role === "prev" && (
        <ArrowBackIos
          sx={{
            color: disabled ? colors.variant.grey100 : colors.point,
          }}
        />
      )}

      <Typography
        fontWeight={800}
        textTransform="uppercase"
        sx={{
          color: disabled ? colors.variant.grey150 : "#fff",
        }}
      >
        {role}
        <br />
        Step
      </Typography>
      {role === "next" && (
        <ArrowForwardIos
          sx={{
            color: disabled ? colors.variant.grey100 : colors.point,
          }}
        />
      )}
    </Stack>
  );
};

export default MStepBt;
