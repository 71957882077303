import React, { useEffect, useRef, useState } from "react";
import { GreyContainer, StyledButton } from "../ui/Common";
import {
  Button,
  Dialog,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CropRotate,
  Refresh,
  Replay,
  SwipeOutlined,
  TouchApp,
} from "@mui/icons-material";
import guideImage from "@image/guide_image.png";
import { colors } from "@assets/styles/colors";
import { FrameSelecBox, ImageSelectBox } from "./VirtualFaceStyle";
import ImageDialog from "../ui/ImageDialog";
import { resultType } from "./VirtualFaceStep";
import { swapApi } from "@assets/api";
import { useNavigate } from "react-router-dom";
import { FixedSizeList, ListChildComponentProps } from "react-window";

interface VirtualFaceCompleteProps {
  type: string | undefined;
  file: File | null;
  setFile: (file: File | null) => void;
  resultState: resultType | null;
  setResultState: (result: resultType | null) => void;
  fixImgList: string[];
  setFixImgList: (fixImgList: string[]) => void;
  selectedImg: string;
  setSelectedImg: (selectedImg: string) => void;
  handleEditUpload: (type: "image" | "video") => Promise<boolean | undefined>;
  handleEdit: () => Promise<boolean | undefined>;
  handleEditFrame: (
    url: string,
    idx: number,
    objectImg?: string
  ) => Promise<boolean | undefined>;
  handleEncodingVideo: () => Promise<boolean | undefined>;
  selectFrame: string;
  setSelectFrame: (selectFrame: string) => void;
  findFrame: string[];
  setFindFrame: (findFrame: string[]) => void;
  handleFrameFace: (url: string) => Promise<boolean | undefined>;
}

const VirtualFaceComplete = (props: VirtualFaceCompleteProps) => {
  const {
    type,
    file,
    setFile,
    resultState,
    setResultState,
    fixImgList,
    setFixImgList,
    selectedImg,
    setSelectedImg,
    handleEdit,
    handleEditUpload,
    handleEditFrame,
    handleEncodingVideo,
    selectFrame,
    setSelectFrame,
    findFrame,
    setFindFrame,
    handleFrameFace,
  } = props;

  const navigate = useNavigate();

  // 이미지
  const [enlargeImage, setEnlargeImage] = useState({
    open: false,
    url: "",
    large: false,
  });

  const handleDownload = () => {
    const imageUrl = resultState ? resultState.url : guideImage;
    // const fileName = "test.png";
    const link = document.createElement("a");
    link.href = imageUrl;
    // link.download = fileName;
    link.click();
  };

  // 수정 상태
  const [fixState, setFixState] = useState(false);

  // 프레임 특정 얼굴 수정
  const [frameEditOpen, setFrameEditOpen] = useState(false);

  // 특정 프레임 index
  const [frameIdx, setFrameIdx] = useState<number | null>(null);

  // 영상 수정
  const frameImg = ({ index, style, data }: ListChildComponentProps) => {
    const item = data[index];
    return (
      <Stack
        style={style}
        justifyContent="center"
        alignItems="center"
        gap="20px"
        flexShrink={0}
      >
        <img
          src={item}
          alt={item}
          className={selectedImg === item ? "selected" : ""}
          style={{
            width: "250px",
            height: "360px",
            objectFit: "contain",
            background: colors.background,
          }}
          onClick={() => {
            console.log("item : " + item, "index : " + index);
          }}
        />
        {/* <Typography fontSize={20} fontWeight={600}>
          {item?.fileName}
        </Typography> */}
        <Stack direction="row" gap="5px">
          <Tooltip title="전체 변환">
            <IconButton
              onClick={async () => {
                // console.log("전체변환 item : " + item);
                const url = new URL(item);
                const urlWithoutQuery = url.origin + url.pathname;
                await handleEditFrame(urlWithoutQuery, index);
              }}
            >
              <Refresh sx={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="특정 변환">
            <IconButton
              onClick={async () => {
                // console.log("특정변환 item : " + item);
                const url = new URL(item);
                const urlWithoutQuery = url.origin + url.pathname;
                const success = await handleFrameFace(urlWithoutQuery);
                if (success) {
                  setFrameEditOpen(true);
                  setFrameIdx(index);
                }
              }}
            >
              <CropRotate sx={{ color: "#fff" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      </Stack>
    );
  };

  // 임시 폴더 삭제 기능
  const handleDeleteTemp = async (url: string) => {
    const formData = new FormData();
    formData.append("temporary_directory", url);
    const res = await swapApi.delete("/delete_temporary", { data: formData });
    console.log(res);
  };

  const resultStateRef = useRef(resultState);

  useEffect(() => {
    resultStateRef.current = resultState;
  }, [resultState]);

  useEffect(() => {
    return () => {
      // console.log("unmount");
      (async () => {
        try {
          if (resultStateRef.current) {
            await handleDeleteTemp(resultStateRef.current.temporary_directory);
            setResultState(null);
          }
        } catch (error) {
          console.error(error);
        }
      })();
    };
  }, []);

  // console.log(resultState);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" mb="10px">
        <Stack
          direction="row"
          alignItems="center"
          gap="5px"
          sx={{
            cursor: "pointer",
            transition: "color 0.2s ease-in-out",
            "&:hover": {
              color: colors.point,
            },
          }}
          onClick={() => {
            navigate("/swap");
          }}
        >
          <Typography fontSize={22} fontWeight={600} textTransform="uppercase">
            새로 변환하기
          </Typography>
          <Replay
            fontSize="large"
            sx={{
              color: colors.point,
            }}
          />
        </Stack>
      </Stack>
      <GreyContainer
        p="40px"
        sx={{
          gap: {
            xs: "20px",
            lg: "40px",
          },
          overflowX: "auto",
        }}
      >
        {fixState ? (
          type === "image" ? (
            <>
              <Typography
                fontWeight={600}
                sx={{
                  fontSize: {
                    xs: "24px",
                    lg: "30px",
                  },
                }}
              >
                변환할 얼굴을 선택해 주세요.
              </Typography>
              <Stack
                gap="40px"
                width="100%"
                flexGrow={1}
                sx={{
                  flexDirection: {
                    xs: "column",
                    lg: "row",
                  },
                }}
              >
                <Stack alignItems="center" gap="20px">
                  <img
                    src={file ? URL.createObjectURL(file) : guideImage}
                    alt="before"
                    style={{
                      width: "100%",
                      maxHeight: "360px",
                      objectFit: "contain",
                    }}
                  />
                  <Typography fontSize={20} fontWeight={600}>
                    변환 전
                  </Typography>
                </Stack>
                <Stack flex={1}>
                  <ImageSelectBox>
                    {fixImgList.map((item, idx) => (
                      <img
                        key={item}
                        src={item}
                        alt={`fixImg${idx + 1}`}
                        className={selectedImg === item ? "selected" : ""}
                        onClick={() => {
                          if (selectedImg === item) {
                            setSelectedImg("");
                          } else {
                            setSelectedImg(item);
                          }
                        }}
                      />
                    ))}
                  </ImageSelectBox>
                  <Stack direction="row" justifyContent="flex-end" gap="20px">
                    <StyledButton
                      background={colors.variant.grey100}
                      onClick={() => {
                        setFixState(false);
                        setSelectedImg("");
                      }}
                    >
                      이전
                    </StyledButton>
                    <StyledButton
                      onClick={async () => {
                        if (!selectedImg)
                          return alert("이미지를 선택해 주세요.");
                        const success = await handleEdit();
                        if (success) {
                          setFixState(false);
                        }
                      }}
                    >
                      변환
                    </StyledButton>
                  </Stack>
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <Typography
                fontWeight={600}
                sx={{
                  fontSize: {
                    xs: "24px",
                    lg: "30px",
                  },
                }}
              >
                수정할 프레임을 선택해 주세요.
              </Typography>
              <Stack
                gap="40px"
                width="100%"
                flexGrow={1}
                alignItems="center"
                // sx={{ overflowX: "auto" }}
              >
                <FixedSizeList
                  className="List"
                  height={500}
                  itemCount={fixImgList.length}
                  itemSize={270}
                  layout="horizontal"
                  width={1000}
                  itemData={fixImgList}
                >
                  {frameImg}
                </FixedSizeList>
                <Stack direction="row" justifyContent="flex-end" gap="20px">
                  <StyledButton
                    background={colors.variant.grey100}
                    onClick={() => {
                      setFixState(false);
                      setFixImgList([]);
                    }}
                  >
                    이전
                  </StyledButton>
                  <StyledButton
                    onClick={async () => {
                      const success = await handleEncodingVideo();
                      if (success) {
                        setFixState(false);
                        setFixImgList([]);
                      }
                    }}
                  >
                    저장
                  </StyledButton>
                </Stack>
              </Stack>
              <Dialog
                open={frameEditOpen}
                onClose={() => {
                  setFrameEditOpen(false);
                  setSelectedImg("");
                  setFindFrame([]);
                }}
                PaperProps={{
                  sx: {
                    background: "#2e2e2e",
                    color: "#fff",
                  },
                }}
                maxWidth="lg"
              >
                <GreyContainer
                  sx={{
                    gap: {
                      xs: "20px",
                      lg: "40px",
                    },
                    overflowX: "auto",
                  }}
                >
                  <Typography
                    fontWeight={600}
                    sx={{
                      fontSize: {
                        xs: "24px",
                        lg: "30px",
                      },
                    }}
                  >
                    변환할 얼굴을 선택해 주세요.
                  </Typography>
                  <Stack
                    gap="40px"
                    width="100%"
                    flexGrow={1}
                    sx={{
                      flexDirection: {
                        xs: "column",
                        lg: "row",
                      },
                    }}
                  >
                    <Stack alignItems="center" gap="20px">
                      <img
                        src={selectFrame ? selectFrame : guideImage}
                        alt="before"
                        style={{
                          width: "100%",
                          maxHeight: "360px",
                          objectFit: "contain",
                        }}
                      />
                      <Typography fontSize={20} fontWeight={600}>
                        변환 전
                      </Typography>
                    </Stack>
                    <Stack flex={1}>
                      <ImageSelectBox>
                        {findFrame.map((item, idx) => (
                          <img
                            key={item}
                            src={item}
                            alt={`fixImg${idx + 1}`}
                            className={selectedImg === item ? "selected" : ""}
                            onClick={() => {
                              if (selectedImg === item) {
                                setSelectedImg("");
                              } else {
                                setSelectedImg(item);
                              }
                            }}
                          />
                        ))}
                      </ImageSelectBox>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        gap="20px"
                      >
                        <StyledButton
                          background={colors.variant.grey100}
                          onClick={() => {
                            setFrameEditOpen(false);
                            setFrameIdx(null);
                            setSelectedImg("");
                            setFindFrame([]);
                          }}
                        >
                          닫기
                        </StyledButton>
                        <StyledButton
                          onClick={async () => {
                            if (!selectFrame || frameIdx === null)
                              return alert(
                                "오류가 발생했습니다. 다시 시도해 주세요."
                              );
                            if (!selectedImg)
                              return alert("이미지를 선택해 주세요.");
                            const success = await handleEditFrame(
                              selectFrame,
                              frameIdx,
                              selectedImg
                            );
                            if (success) {
                              setFrameEditOpen(false);
                              setFrameIdx(null);
                              setSelectedImg("");
                              setFindFrame([]);
                            }
                          }}
                        >
                          변환
                        </StyledButton>
                      </Stack>
                    </Stack>
                  </Stack>
                </GreyContainer>
              </Dialog>
            </>
          )
        ) : (
          <>
            <Stack alignItems="center">
              <Typography
                fontWeight={600}
                sx={{
                  fontSize: {
                    xs: "24px",
                    lg: "30px",
                  },
                }}
              >
                변환 결과물 확인
              </Typography>
              <Stack
                direction="row"
                alignItems="center"
                gap="5px"
                sx={{ color: colors.variant.grey150 }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "18px",
                      lg: "24px",
                    },
                  }}
                >
                  Click the image for more details
                </Typography>
                <TouchApp />
              </Stack>
            </Stack>
            <Stack
              width="100%"
              flexGrow={1}
              sx={{
                flexDirection: {
                  xs: "column",
                  lg: "row",
                },
                gap: {
                  xs: "20px",
                  lg: "40px",
                },
              }}
            >
              <Stack
                flex={1}
                direction="row"
                sx={{
                  justifyContent: {
                    xs: "space-around",
                    md: "center",
                  },
                  gap: {
                    xs: "20px",
                    md: "80px",
                  },
                }}
              >
                <Stack justifyContent="center" alignItems="center" gap="20px">
                  {type === "image" ? (
                    <img
                      src={file ? URL.createObjectURL(file) : guideImage}
                      alt="before"
                      style={{
                        width: "100%",
                        maxHeight: "360px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setEnlargeImage({
                          open: true,
                          url: file ? URL.createObjectURL(file) : guideImage,
                          large: false,
                        })
                      }
                    />
                  ) : (
                    <video
                      controls
                      src={file ? URL.createObjectURL(file) : guideImage}
                      style={{
                        width: "100%",
                        maxHeight: "360px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <Typography fontSize={20} fontWeight={600}>
                    변환 전
                  </Typography>
                </Stack>
                <Stack justifyContent="center" alignItems="center" gap="20px">
                  {type === "image" ? (
                    <img
                      src={resultState ? resultState.url : guideImage}
                      alt="before"
                      style={{
                        width: "100%",
                        maxHeight: "360px",
                        objectFit: "contain",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setEnlargeImage({
                          open: true,
                          url: resultState ? resultState.url : guideImage,
                          large: false,
                        })
                      }
                    />
                  ) : (
                    <video
                      controls
                      src={resultState ? resultState.url : guideImage}
                      style={{
                        width: "100%",
                        maxHeight: "360px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <Typography fontSize={20} fontWeight={600}>
                    변환 후
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
                gap="20px"
              >
                <StyledButton
                  background={colors.variant.grey100}
                  onClick={async () => {
                    const success = await handleEditUpload(
                      type === "image" ? "image" : "video"
                    );
                    if (success) {
                      setFixState(true);
                    }
                  }}
                >
                  수정
                </StyledButton>
                <StyledButton onClick={handleDownload}>저장</StyledButton>
              </Stack>
            </Stack>
          </>
        )}
        <ImageDialog
          enlargeImage={enlargeImage}
          setEnlargeImage={setEnlargeImage}
        />
      </GreyContainer>
    </>
  );
};

export default VirtualFaceComplete;
