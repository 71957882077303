import { Stack, Typography } from "@mui/material";
import React from "react";
import { StepBtBox } from "../virtualFace/VirtualFaceStyle";
import { colors } from "@assets/styles/colors";

interface StepBtContainerProps {
  role: "prev" | "next";
  disabled?: boolean;
  handleClick: () => void;
}

const StepBtContainer = (props: StepBtContainerProps) => {
  const { role, disabled, handleClick } = props;

  return (
    <Stack
      width="75px"
      justifyContent="center"
      sx={{
        display: {
          xs: "none",
          md: "flex",
        },
      }}
    >
      <StepBtBox role={role} disabled={disabled} onClick={handleClick}>
        <div className="stepBt">
          <div
            className={role === "prev" ? "stepPrevArrow" : "stepNextArrow"}
          />
        </div>
        <Typography
          fontWeight={800}
          textTransform="uppercase"
          sx={{
            fontSize: {
              xs: "12px",
              md: "16px",
            },
            color: disabled ? colors.variant.grey150 : "#fff",
          }}
        >
          {role}
          <br />
          Step
        </Typography>
      </StepBtBox>
    </Stack>
  );
};

export default StepBtContainer;
