import React, { useState } from "react";
import { GreyContainer, StyledButton } from "../ui/Common";
import { Button, Slider, Stack, Typography, styled } from "@mui/material";
import { StepBtBox, VirtualSelectBt } from "./VirtualFaceStyle";
import { colors } from "@assets/styles/colors";
import { ArrowForwardIos, ChangeCircle } from "@mui/icons-material";
import StepBtContainer from "../ui/StepBtContainer";
import MStepBt from "../ui/MStepBt";

interface VirtualFaceSettingProps {
  type: string | undefined;
  file: File | null;
  setFile: (file: File | null) => void;
  setActiveStep: (step: number) => void;
  threshold: number;
  setThreshold: (threshold: number) => void;
  swapValue: number;
  setSwapValue: (swapValue: number) => void;
  handleSubmit: () => void;
}

const CustomSlider = styled(Slider)(({ theme }) => ({
  "& .MuiSlider-thumb": {
    height: 25,
    width: 25,
    boxShadow: "0px 0px 0px 8px rgba(255, 100, 16, 0.16)",
  },
  "& .MuiSlider-track": {
    border: "none",
    backgroundColor: colors.point,
  },
  "& .MuiSlider-rail": {
    backgroundColor: colors.background,
  },
}));

const VirtualFaceSetting = (props: VirtualFaceSettingProps) => {
  const {
    type,
    file,
    setFile,
    setActiveStep,
    threshold,
    setThreshold,
    swapValue,
    setSwapValue,
    handleSubmit,
  } = props;

  const handleChange = (event: Event, newValue: number | number[]) => {
    setThreshold(newValue as number);
  };

  return (
    <Stack
      sx={{
        flexDirection: {
          xs: "column",
          md: "row",
        },
        gap: {
          xs: "20px",
          md: "40px",
        },
      }}
    >
      <StepBtContainer
        role="prev"
        handleClick={() => {
          setActiveStep(1);
        }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        gap="20px"
        sx={{
          display: {
            xs: "flex",
            md: "none",
          },
        }}
      >
        <MStepBt
          role="prev"
          handleClick={() => {
            setActiveStep(1);
          }}
        />
        <Stack
          direction="row"
          alignItems="center"
          gap="5px"
          sx={{
            cursor: "pointer",
          }}
          onClick={handleSubmit}
        >
          <Stack alignItems="center">
            <ChangeCircle
              sx={{
                fontSize: "20px",
                color: colors.point,
              }}
            />
            <Typography fontWeight={800} textTransform="uppercase">
              Swap
            </Typography>
          </Stack>
          <ArrowForwardIos
            sx={{
              color: colors.point,
            }}
          />
        </Stack>
      </Stack>
      <GreyContainer p="40px" height="600px">
        <Stack
          flexGrow={1}
          gap="40px"
          sx={{
            width: "100%",
            flexDirection: {
              xs: "column",
              lg: "row",
            },
          }}
        >
          <Stack
            flex={1}
            justifyContent="center"
            alignItems="center"
            gap="40px"
          >
            <CustomSlider
              aria-label="Detection Threshold"
              value={threshold}
              step={0.01}
              min={0.01}
              max={1}
              onChange={handleChange}
            />
            <Typography
              fontWeight={600}
              sx={{
                fontSize: {
                  xs: "20px",
                  md: "28px",
                },
              }}
            >
              Face Detection Threshold : {threshold}
            </Typography>
          </Stack>
          <Stack
            flex={1}
            gap="40px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <VirtualSelectBt
              selected={swapValue === 1}
              onClick={() => {
                setSwapValue(1);
              }}
            >
              SRmode1
            </VirtualSelectBt>
            <VirtualSelectBt
              selected={swapValue === 2}
              onClick={() => {
                setSwapValue(2);
              }}
            >
              SRmode2
            </VirtualSelectBt>
          </Stack>
        </Stack>
        {/* <Stack width="100%" alignItems="flex-end">
          <Button
            variant="contained"
            startIcon={<ChangeCircle sx={{ fontSize: "24px !important" }} />}
            sx={{
              width: "150px",
              height: "55px",
              fontSize: {
                xs: "20px",
                md: "24px",
              },
              fontWeight: 800,
              color: "#fff",
              // background: colors.point,
              background: "#fb2808 !important",
              borderRadius: "10px",
              boxShadow: "none",
              textTransform: "none",
            }}
            onClick={handleSubmit}
          >
            Swap
          </Button>
        </Stack> */}
      </GreyContainer>
      <Stack
        width="75px"
        justifyContent="center"
        sx={{
          display: {
            xs: "none",
            md: "flex",
          },
        }}
      >
        <StepBtBox role="next" onClick={handleSubmit}>
          <div className="stepBt">
            <div className="stepNextArrow" />
          </div>
          <Stack alignItems="center" gap="2px">
            <ChangeCircle
              sx={{
                fontSize: "28px",
                color: colors.point,
              }}
            />
            <Typography
              fontWeight={800}
              textTransform="uppercase"
              sx={{
                fontSize: {
                  xs: "12px",
                  md: "16px",
                },
              }}
            >
              Swap
            </Typography>
          </Stack>
        </StepBtBox>
      </Stack>
    </Stack>
  );
};

export default VirtualFaceSetting;
