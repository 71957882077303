import { colors } from "@assets/styles/colors";
import { Button, Popover, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { VirtualSelectBt, VisuallyHiddenInput } from "./VirtualFaceStyle";
import {
  CloudUpload,
  PeopleAltOutlined,
  PersonOutline,
} from "@mui/icons-material";
import { GreyContainer, StyledButton } from "../ui/Common";
import guideImage from "@image/Group 17.png";
import DndBox from "../ui/DndBox";
import StepBtContainer from "../ui/StepBtContainer";
import ProgramGuideBox from "../ui/ProgramGuideBox";
import MStepBt from "../ui/MStepBt";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../util/useDebounceEffect";
import { canvasPreview } from "../util/canvasPreview";

interface VirtualFaceSourceProps {
  type: string | undefined;
  setActiveStep: (step: number) => void;
  file: File | null;
  setFile: (file: File | null) => void;
  objectFile: File | null;
  setObjectFile: (file: File | null) => void;
  sourceFile: File | null;
  setSourceFile: (file: File | null) => void;
}

const VirtualFaceSource = (props: VirtualFaceSourceProps) => {
  const {
    type,
    file,
    setFile,
    setActiveStep,
    objectFile,
    setObjectFile,
    sourceFile,
    setSourceFile,
  } = props;

  const [swapMode, setSwapMode] = useState(0);

  // 특정 얼굴 선택
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop);
      }
    },
    100,
    [completedCrop]
  );

  const handleNextPage = async () => {
    const canvas = previewCanvasRef.current;
    if (!canvas) {
      console.error("선택된 이미지가 없습니다.");
      return;
    }

    canvas.toBlob((blob) => {
      if (!blob) {
        console.error("오류가 발생했습니다. 다시 시도해 주세요.");
        return;
      }

      const file = new File([blob], "object.png", { type: "image/png" });

      setObjectFile(file);
      setSwapMode(2);
    }, "image/png");
  };

  const selectGuideProps = {
    guide:
      type === "image"
        ? file
          ? URL.createObjectURL(file)
          : guideImage
        : guideImage,
    step: 2,
    title: "대상 얼굴 선택하기",
    description:
      type === "image" ? (
        <>
          변환 하고자 하는 <b style={{ fontWeight: 800 }}>대상</b> 얼굴을 선택해
          주세요.
          <br />
          선택하신 <b style={{ fontWeight: 800 }}>대상</b> 얼굴이 변환됩니다.
        </>
      ) : (
        <>
          영상에서 변환 하고자 하는 <b style={{ fontWeight: 800 }}>대상</b>{" "}
          얼굴을 업로드 해 주세요.
          <br />
          선택하신 <b style={{ fontWeight: 800 }}>대상</b> 얼굴이 변환됩니다.
        </>
      ),
  };

  const sourceGuideProps = {
    guide: guideImage,
    step: 2,
    title: "가상 얼굴 선택하기",
    description: `변환 하고자 하는 가상 얼굴을 업로드 해 주세요. 선택하신 가상 얼굴로 ${
      type === '"image' ? "사진" : "영상"
    }이 변환됩니다.`,
  };

  return (
    <Stack
      sx={{
        gap: {
          xs: "20px",
          md: "40px",
        },
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <StepBtContainer
        role="prev"
        handleClick={() => {
          setActiveStep(0);
          setSourceFile(null);
          setObjectFile(null);
        }}
      />
      <Stack gap="40px" flex={1}>
        {swapMode !== 0 ? (
          <ProgramGuideBox
            {...(swapMode === 1 ? selectGuideProps : sourceGuideProps)}
          />
        ) : null}
        <Stack
          direction="row"
          justifyContent="space-between"
          gap="20px"
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
          }}
        >
          <MStepBt
            role="prev"
            handleClick={() => {
              setActiveStep(0);
              setSourceFile(null);
              setObjectFile(null);
            }}
          />
          <MStepBt
            role="next"
            disabled={!sourceFile}
            handleClick={() => {
              if (sourceFile) {
                setActiveStep(2);
              }
            }}
          />
        </Stack>
        {swapMode === 0 ? (
          <Stack
            flex={1}
            gap="40px"
            sx={{
              background: colors.variant.grey50,
              borderRadius: "100px",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
              p: {
                xs: "40px",
                lg: "40px 100px",
              },
            }}
          >
            <VirtualSelectBt
              height="60vh"
              onClick={() => {
                setSwapMode(2);
              }}
            >
              <Stack alignItems="center" gap="20px">
                <Typography
                  fontWeight={700}
                  sx={{
                    fontSize: {
                      xs: "24px",
                      md: "30px",
                    },
                  }}
                >
                  모든 얼굴 변환
                </Typography>
                <PeopleAltOutlined
                  sx={{
                    fontSize: {
                      xs: "80px",
                      lg: "125px",
                    },
                  }}
                />
                <Typography
                  fontWeight={500}
                  textAlign="center"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "20px",
                    },
                  }}
                >
                  컨텐츠에서 인식되는
                  <br />
                  모든 얼굴이 변환됩니다.
                </Typography>
              </Stack>
            </VirtualSelectBt>
            <VirtualSelectBt
              height="60vh"
              onClick={() => {
                setSwapMode(1);
              }}
            >
              <Stack alignItems="center" gap="20px">
                <Typography
                  fontWeight={700}
                  sx={{
                    fontSize: {
                      xs: "24px",
                      md: "30px",
                    },
                  }}
                >
                  특정 얼굴 변환
                </Typography>
                <PersonOutline
                  sx={{
                    fontSize: {
                      xs: "80px",
                      lg: "125px",
                    },
                  }}
                />
                <Typography
                  fontWeight={500}
                  textAlign="center"
                  sx={{
                    fontSize: {
                      xs: "16px",
                      md: "20px",
                    },
                  }}
                >
                  컨텐츠에 여러명의 인물이 나온다면
                  <br />
                  사용을 추천드립니다.
                </Typography>
              </Stack>
            </VirtualSelectBt>
          </Stack>
        ) : swapMode === 1 && type === "image" ? (
          <>
            <GreyContainer>
              <Stack
                width="100%"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  flexDirection: {
                    xs: "column",
                    md: "row",
                  },
                  gap: "20px",
                }}
              >
                <ReactCrop
                  crop={crop}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={undefined}
                  minWidth={50}
                  minHeight={50}
                  style={{
                    // width: "60%",
                    maxWidth: "50vh",
                    // height: "60vh",
                  }}
                >
                  <img
                    ref={imgRef}
                    alt="Crop"
                    src={file ? URL.createObjectURL(file) : guideImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      background: colors.background,
                      // objectFit: "contain",
                    }}
                  />
                </ReactCrop>
                <Stack
                  width="40%"
                  justifyContent="center"
                  alignItems="center"
                  gap="20px"
                >
                  {!!completedCrop && completedCrop.width ? (
                    <>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          border: "1px solid black",
                          objectFit: "contain",
                          width: completedCrop.width,
                          height: completedCrop.height,
                        }}
                      />
                      <StyledButton
                        sx={{
                          width: "100px",
                        }}
                        onClick={handleNextPage}
                      >
                        다음
                      </StyledButton>
                    </>
                  ) : (
                    <>
                      <Typography
                        fontSize={20}
                        fontWeight={500}
                        textAlign="center"
                      >
                        변환 하고자 하는
                        <br />
                        대상 얼굴의 영역을 선택해 주세요.
                      </Typography>
                    </>
                  )}
                </Stack>
              </Stack>
            </GreyContainer>
          </>
        ) : swapMode === 1 ? (
          <>
            <DndBox file={objectFile} setFile={setObjectFile}>
              {objectFile ? (
                <>
                  <img
                    src={URL.createObjectURL(objectFile)}
                    alt="upload"
                    height="250px"
                    style={{
                      width: "100%",
                      maxWidth: "650px",
                      objectFit: "contain",
                    }}
                  />
                  <Stack direction="row" gap="20px">
                    <Button
                      component="label"
                      variant="contained"
                      sx={{
                        fontSize: {
                          xs: "16px",
                          md: "20px",
                        },
                        fontWeight: 800,
                        color: "#fff",
                        background: colors.variant.grey150 + " !important",
                        borderRadius: "10px",
                        boxShadow: "none",
                        textTransform: "none",
                      }}
                    >
                      Change File
                      <VisuallyHiddenInput
                        type="file"
                        accept={"image/jpeg, image/jpg, image/png"}
                        onChange={(e) => {
                          setObjectFile(
                            e.target.files ? e.target.files[0] : null
                          );
                        }}
                      />
                    </Button>
                    <StyledButton
                      background={colors.point}
                      onClick={() => {
                        setSwapMode(2);
                      }}
                    >
                      다음
                    </StyledButton>
                  </Stack>
                </>
              ) : (
                <>
                  <Typography fontSize={30} fontWeight={800}>
                    Specific File Upload
                  </Typography>
                  <Stack justifyContent="center" alignItems="center">
                    <CloudUpload sx={{ fontSize: "125px", color: "#1a1a1a" }} />
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color={colors.variant.grey150}
                      textAlign="center"
                    >
                      [파일업로드]
                      <br />
                      변환하고자 하는 대상 얼굴 이미지를 업로드 해주세요.
                    </Typography>
                  </Stack>
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    File Upload
                    <VisuallyHiddenInput
                      type="file"
                      accept={"image/jpeg, image/jpg, image/png"}
                      onChange={(e) => {
                        setObjectFile(
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </Button>
                </>
              )}
            </DndBox>
          </>
        ) : (
          <>
            <DndBox file={sourceFile} setFile={setSourceFile}>
              {sourceFile ? (
                <>
                  <img
                    src={URL.createObjectURL(sourceFile)}
                    alt="upload"
                    height="250px"
                    style={{
                      width: "100%",
                      maxWidth: "650px",
                      objectFit: "contain",
                    }}
                  />
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    Change File
                    <VisuallyHiddenInput
                      type="file"
                      accept={"image/jpeg, image/jpg, image/png"}
                      onChange={(e) => {
                        setSourceFile(
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </Button>
                </>
              ) : (
                <>
                  <Typography fontSize={30} fontWeight={800}>
                    Source File Upload
                  </Typography>
                  <Stack justifyContent="center" alignItems="center">
                    <CloudUpload sx={{ fontSize: "125px", color: "#1a1a1a" }} />
                    <Typography
                      fontSize={16}
                      fontWeight={500}
                      color={colors.variant.grey150}
                      textAlign="center"
                    >
                      [파일업로드]
                      <br />
                      변환하고자 하는 가상 얼굴을 업로드 해주세요.
                    </Typography>
                  </Stack>
                  <Button
                    component="label"
                    variant="contained"
                    sx={{
                      fontSize: {
                        xs: "16px",
                        md: "20px",
                      },
                      fontWeight: 800,
                      color: "#fff",
                      background: colors.point,
                      borderRadius: "10px",
                      boxShadow: "none",
                      textTransform: "none",
                    }}
                  >
                    File Upload
                    <VisuallyHiddenInput
                      type="file"
                      accept={"image/jpeg, image/jpg, image/png"}
                      onChange={(e) => {
                        setSourceFile(
                          e.target.files ? e.target.files[0] : null
                        );
                      }}
                    />
                  </Button>
                </>
              )}
            </DndBox>
          </>
        )}
      </Stack>
      <StepBtContainer
        role="next"
        disabled={!sourceFile}
        handleClick={() => {
          if (sourceFile) {
            setActiveStep(2);
          }
        }}
      />
    </Stack>
  );
};

export default VirtualFaceSource;
