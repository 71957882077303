import { colors } from "@assets/styles/colors";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { ContentsContainer } from "../ui/Common";
import { VirtualSelectBt } from "./VirtualFaceStyle";
import { useNavigate } from "react-router-dom";
import {
  InsertPhotoOutlined,
  PhotoSizeSelectActualOutlined,
  SmartDisplay,
} from "@mui/icons-material";

const VirtualFaceSelect = () => {
  const navigate = useNavigate();
  return (
    <ContentsContainer justifyContent="center" alignItems="center">
      <Stack
        gap="40px"
        width="80%"
        sx={{
          background: colors.variant.grey50,
          borderRadius: "100px",
          flexDirection: {
            xs: "column",
            lg: "row",
          },
          p: {
            xs: "40px",
            lg: "40px 100px",
          },
        }}
      >
        <VirtualSelectBt
          height="60vh"
          onClick={() => {
            navigate("/swap/image");
          }}
        >
          <Stack alignItems="center" gap="20px" py="20px">
            <Typography
              fontWeight={600}
              sx={{
                fontSize: {
                  xs: "32px",
                  lg: "40px",
                },
              }}
            >
              사진 변환
            </Typography>
            <PhotoSizeSelectActualOutlined
              sx={{
                fontSize: {
                  xs: "80px",
                  lg: "125px",
                },
              }}
            />
          </Stack>
        </VirtualSelectBt>
        <VirtualSelectBt
          height="60vh"
          onClick={() => {
            navigate("/swap/video");
          }}
        >
          <Stack alignItems="center" gap="20px" py="20px">
            <Typography
              fontWeight={600}
              sx={{
                fontSize: {
                  xs: "32px",
                  lg: "40px",
                },
              }}
            >
              영상 변환
            </Typography>
            <SmartDisplay
              sx={{
                fontSize: {
                  xs: "80px",
                  lg: "125px",
                },
              }}
            />
          </Stack>
        </VirtualSelectBt>
      </Stack>
    </ContentsContainer>
  );
};

export default VirtualFaceSelect;
