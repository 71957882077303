import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { colors } from "@colors";
import { useNavigate } from "react-router";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  PersonOutline,
  Visibility,
  VisibilityOff,
  VpnKeyOutlined,
} from "@mui/icons-material";
import loginBanner from "@image/login_banner.png";
import { Auth } from "aws-amplify";
import { StyledInput } from "src/feature/ui/Common";
import SignInBox from "./SignInBox";
import SignUpBox from "./SignUpBox";

// type Inputs = {
//   id: string;
//   password: string;
// };

const SignIn = () => {
  const [page, setPage] = useState(0);

  const [id, setId] = useState("");

  return (
    <Stack
      sx={{
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack
        width="35%"
        justifyContent="center"
        alignItems="center"
        sx={{ background: "#2e2e2e" }}
      >
        <img src={loginBanner} alt="loginBanner" style={{ width: "100%" }} />
        <Stack
          width="60%"
          py="30px"
          gap="10px"
          justifyContent="center"
          alignItems="center"
        >
          {/* {page === 0 ? (
            <SignInBox id={id} setId={setId} setPage={setPage} />
          ) : (
            <SignUpBox setId={setId} setPage={setPage} />
          )} */}
          <SignInBox id={id} setId={setId} setPage={setPage} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SignIn;
