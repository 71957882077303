import React, { useState } from "react";
import {
  ContentsContainer,
  GreyContainer,
  StyledButton,
  TextButton,
} from "../ui/Common";
import { Dialog, Stack, Typography } from "@mui/material";
import { colors } from "@assets/styles/colors";
import {
  AccountCircleOutlined,
  Close,
  PlayCircleOutlined,
} from "@mui/icons-material";
import {
  ModelSortDirection,
  VContent,
  useInfiniteVContentByUserQuery,
} from "src/lib/api";
import moment from "moment";
import { FixedSizeList } from "react-window";
import awsmobile from "@assets/aws-exports";
import loadingImg from "@image/imageLoading.png";

const VContentsList = () => {
  const key = `CognitoIdentityServiceProvider.${awsmobile.aws_user_pools_web_client_id}.LastAuthUser`;
  const userId = localStorage.getItem(key);

  const vContentData = useInfiniteVContentByUserQuery(
    "nextToken",
    {
      userID: userId ?? "test@test.com",
      sortDirection: ModelSortDirection.Desc,
      limit: 200,
    },
    {
      getNextPageParam: (lastPage) => {
        const nextPageToken = lastPage?.VContentByUser?.nextToken;
        return nextPageToken ? lastPage?.VContentByUser : undefined;
      },
    }
  );

  const [mode, setMode] = useState("image");

  const contentList =
    vContentData?.data?.pages.flatMap((page) => page.VContentByUser?.items) ??
    [];

  const itemData = contentList?.filter((item) => item?.type === mode);

  // console.log(itemData);

  const [selectedImg, setSelectedImg] = useState<VContent | null>(null);
  const [imageOpen, setImageOpen] = useState(false);

  const handleClose = () => {
    setImageOpen(false);
    // setSelectedImg(null);
  };

  // console.log(selectedImg);

  // function generateFrameUrls(baseURL: string, frameCount: number) {
  //   const urls = [];
  //   for (let i = 0; i < frameCount; i++) {
  //     const paddedIndex = String(i).padStart(7, "0");
  //     const url = `${baseURL}/frame_${paddedIndex}.jpg`;
  //     urls.push(url);
  //   }
  //   return urls;
  // }

  // // 사용 예:
  // const baseURL =
  //   "https://fliption-web-bucket-dev.s3.ap-northeast-2.amazonaws.com/cj-test/frame-image/2024/3/19/frame-17108298188337843";
  // const frameCount = 165;
  // const urls = generateFrameUrls(baseURL, frameCount);
  // console.log(urls);

  return (
    <ContentsContainer alignItems="center" gap="40px">
      <Typography fontSize={30} fontWeight={700}>
        V Contents List
      </Typography>
      <Stack width="100%" gap="10px">
        <Stack
          width="100%"
          direction="row"
          justifyContent="flex-end"
          gap="20px"
        >
          <TextButton
            selected={mode === "image"}
            onClick={() => {
              setMode("image");
            }}
            fontSize={20}
          >
            <AccountCircleOutlined sx={{ mb: "3px" }} />
            &nbsp;Image
          </TextButton>
          <TextButton
            selected={mode === "video"}
            onClick={() => {
              setMode("video");
            }}
            fontSize={20}
          >
            <PlayCircleOutlined sx={{ mb: "3px" }} />
            &nbsp;Video
          </TextButton>
        </Stack>
        <GreyContainer
          width="100%"
          px="20px"
          py="40px"
          sx={{
            height: "50vh",
            gap: {
              xs: "20px",
              lg: "40px",
            },
            overflowX: "auto",
          }}
        >
          <FixedSizeList
            height={500}
            itemCount={Math.ceil(itemData.length / 3)}
            itemSize={260}
            width={1000}
          >
            {({ index, style }) => {
              // const items = itemData.slice(index * 3, index * 3 + 3);
              if (
                index * 3 + 3 >= itemData.length &&
                vContentData.hasNextPage &&
                !vContentData.isFetchingNextPage
              ) {
                // console.log("갱신");
                vContentData.fetchNextPage();
                return <div style={style}>Loading...</div>;
              }

              const items = itemData.slice(index * 3, index * 3 + 3);
              return (
                <div style={{ ...style, display: "flex" }}>
                  {items.map((item, i) => (
                    <div
                      key={i}
                      style={{
                        margin: "1%",
                        width: "31%",
                        display: "flex",
                        flex: "none",
                        alignContent: "stretch",
                        boxSizing: "border-box",
                      }}
                    >
                      {mode === "image" ? (
                        <img
                          src={item?.storage}
                          alt={item?.fileName}
                          loading="lazy"
                          style={{
                            width: "100%",
                            height: "250px",
                            background: colors.background,
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (item) {
                              setSelectedImg(item);
                            }
                            setImageOpen(true);
                          }}
                          onError={(e) => {
                            const target = e.target as HTMLImageElement;
                            target.onerror = null;
                            target.src = loadingImg;
                            e.currentTarget.onerror = null;
                          }}
                        />
                      ) : (
                        <video
                          src={`${item?.storage}`}
                          style={{
                            width: "100%",
                            height: "250px",
                            background: colors.background,
                            objectFit: "contain",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (item) {
                              setSelectedImg(item);
                            }
                            setImageOpen(true);
                          }}
                          onError={(e) => {
                            const target = e.target as HTMLVideoElement;
                            target.style.backgroundImage = `url(${loadingImg})`;
                            target.style.backgroundSize = "contain";
                            target.style.backgroundRepeat = "no-repeat";
                          }}
                        />
                      )}
                    </div>
                  ))}
                </div>
              );
            }}
          </FixedSizeList>
          {/* <ImageList
            gap={20}
            sx={{
              width: "100%",
              gridTemplateColumns: {
                xs: "repeat(2, 1fr) !important",
                md: "repeat(3, 1fr) !important",
                lg: "repeat(4, 1fr) !important",
              },
              px: "20px",
            }}
          >
            {itemData.map((item, idx) => (
              <ImageListItem
                key={idx}
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (item) {
                    setSelectedImg(item);
                  }
                  setImageOpen(true);
                }}
              >
                {mode === "image" ? (
                  <img
                    // srcSet={`${item?.storage}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item?.storage}`}
                    alt={item?.fileName}
                    loading="lazy"
                    style={{
                      width: "100%",
                      maxWidth: "40vw",
                      height: "250px",
                      background: colors.background,
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <video
                    // srcSet={`${item?.storage}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    src={`${item?.storage}`}
                    controls
                    style={{
                      width: "100%",
                      maxWidth: "50vw",
                      height: "250px",
                      background: colors.background,
                      objectFit: "contain",
                    }}
                    onPlay={(e) => e.preventDefault()}
                  />
                )}
                <ImageListItemBar
                  // title={item?.fileName}
                  title={moment(item?.createdAt).format("YYYY.MM.DD hh:mm")}
                  position="below"
                />
              </ImageListItem>
            ))}
          </ImageList> */}
        </GreyContainer>
        {/* <GreyContainer
          p="40px"
          sx={{
            gap: {
              xs: "20px",
              lg: "40px",
            },
            overflowX: "auto",
          }}
        >
          <>
            <Typography
              fontWeight={600}
              sx={{
                fontSize: {
                  xs: "24px",
                  lg: "30px",
                },
              }}
            >
              수정할 프레임을 선택해 주세요.
            </Typography>
            <Stack
              gap="40px"
              width="100%"
              flexGrow={1}
              alignItems="center"
              // sx={{ overflowX: "auto" }}
            >
              <FixedSizeList
                className="List"
                height={500}
                itemCount={itemData.length}
                itemSize={270}
                layout="horizontal"
                width={1000}
                itemData={itemData}
              >
                {Row}
              </FixedSizeList>
              <Stack direction="row" justifyContent="flex-end" gap="20px">
                <StyledButton
                  background={colors.variant.grey100}
                  onClick={() => {}}
                >
                  이전
                </StyledButton>
                <StyledButton>저장</StyledButton>
              </Stack>
            </Stack>
          </>
        </GreyContainer> */}
      </Stack>
      <Dialog
        open={imageOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: "70%",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            p: "20px",
            gap: "20px",
            background: colors.variant.grey100,
          },
        }}
      >
        <Stack flex={1}>
          {mode === "image" ? (
            <img
              src={selectedImg?.storage}
              alt={selectedImg?.fileName}
              loading="lazy"
              style={{
                width: "100%",
                height: "60vh",
                background: colors.background,
                objectFit: "contain",
              }}
              onError={(e) => {
                const target = e.target as HTMLImageElement;
                target.onerror = null;
                target.src = loadingImg;
                e.currentTarget.onerror = null;
              }}
            />
          ) : (
            <video
              src={`${selectedImg?.storage}`}
              controls
              style={{
                width: "100%",
                // height: "100%",
                height: "60vh",
                background: colors.background,
                objectFit: "contain",
              }}
              onError={(e) => {
                const target = e.target as HTMLVideoElement;
                target.style.backgroundImage = `url(${loadingImg})`;
                target.style.backgroundSize = "contain";
                target.style.backgroundRepeat = "no-repeat";
              }}
            />
          )}
        </Stack>
        <Stack flex={1} position="relative">
          <Close
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={handleClose}
          />
          <Stack flexGrow={1} sx={{ color: "#fff" }}>
            <Typography fontSize={20} fontWeight={500}>
              {selectedImg?.fileName}
            </Typography>
            <Typography>
              {moment(selectedImg?.createdAt).format("YYYY.MM.DD hh:mm")}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            gap="20px"
          >
            <StyledButton
              background={colors.variant.grey150}
              sx={{
                width: "100px",
              }}
              onClick={() => {
                setImageOpen(false);
              }}
            >
              닫기
            </StyledButton>
            <StyledButton
              sx={{
                width: "100px",
              }}
            >
              저장
            </StyledButton>
          </Stack>
        </Stack>
      </Dialog>
    </ContentsContainer>
  );
};

export default VContentsList;
